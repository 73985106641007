<script setup lang="ts">
const store = useRoomStore()
const { room } = storeToRefs(store)
</script>

<template>
  <div
    container="padded"
    grid="~ cols-[1fr_1fr_1fr]"
    class="fixed inset-x-0 top-0 z-20 gap-4 bg-gray-100/70 py-2 backdrop-blur-sm md:sticky md:bg-white md:backdrop-blur-none md:dark:bg-gray-950 dark:bg-gray-950/90"
    border="b base"
  >
    <!-- Left -->
    <AppLogo />

    <!-- Center -->
    <div
      col-span="1"
      class="mx-auto grow items-center justify-center whitespace-nowrap"
    >
      <GButton
        class="-ml-2.5"
        :label="`Interview with: ${room?.applicantName || 'Anonymous'}`"
        leading="i-ph-briefcase-duotone md:text-accent mr-1.5"
        btn="~"
      />
    </div>

    <!-- Right -->
    <div
      col-span="1"
      class="ml-auto"
    >
      <!-- Desktop -->
      <div class="hidden gap-x-3 md:inline-flex">
        <GThemeSwitcher />
        <SettingsColorMode />
        <GButton
          btn="text-gray"
          square
          label="i-ph-question"
          icon
        />
      </div>

      <!-- Mobile -->
      <GButton
        class="block md:hidden"
        square
        btn="text-gray hover:text-primary"
        label="i-ph-dots-three-outline-fill"
        icon
      />
    </div>
  </div>
</template>
