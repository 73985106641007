<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="317.532" height="129.475" viewBox="0 0 317.532 129.475">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_3639" data-name="Rectangle 3639" width="221.687" height="125.111" fill="#fff" stroke="#707070" stroke-width="1" />
      </clipPath>
      <linearGradient id="linear-gradient" x1="0.5" y1="-0.038" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ff4e66" />
        <stop offset="0.284" stop-color="#ff3386" />
        <stop offset="1" stop-color="#1560f6" />
      </linearGradient>
    </defs>
    <g id="Group_6344" data-name="Group 6344" transform="translate(-1214.5 -213)">
      <g id="Mask_Group_6101" data-name="Mask Group 6101" transform="translate(1259.496 213)" clip-path="url(#clip-path)">
        <circle id="Ellipse_1518" data-name="Ellipse 1518" cx="98.772" cy="98.772" r="98.772" transform="translate(15.364 29.997)" style="fill: rgb(var(--una-primary-600))" opacity="0.1" />
      </g>
      <g id="Group_6264" data-name="Group 6264" transform="translate(1214.5 338.111)">
        <line id="Line_1795" data-name="Line 1795" x2="317.532" fill="none" stroke="#e8e9ee" stroke-width="1" />
      </g>
      <g id="Group_6297" data-name="Group 6297" transform="translate(1306.548 227.142) rotate(11)">
        <rect id="Rectangle_3640" data-name="Rectangle 3640" width="2.195" height="8.78" rx="1" transform="matrix(-0.966, 0.259, -0.259, -0.966, 15.676, 8.481)" style="fill: rgb(var(--una-primary-600))" />
        <rect id="Rectangle_3641" data-name="Rectangle 3641" width="2.195" height="8.78" rx="1" transform="translate(9.973 10.038) rotate(135)" style="fill: rgb(var(--una-primary-600))" />
        <rect id="Rectangle_3642" data-name="Rectangle 3642" width="2.195" height="8.78" rx="1" transform="matrix(0.017, 1, -1, 0.017, 8.778, 14.666)" style="fill: rgb(var(--una-primary-600))" />
      </g>
      <g id="Group_6361" data-name="Group 6361" transform="translate(410.386 -59.241)">
        <path id="Path_6978" data-name="Path 6978" d="M11.5,68.424V53.781H6.433a9.5,9.5,0,0,1-4.652-2.09A7.976,7.976,0,0,1,0,47.211V8.072A10.2,10.2,0,0,1,1.78,3.012,11.284,11.284,0,0,1,6.433,0h41.19s3.816.115,5.732,2.135,1.932,5.938,1.932,5.938V47.211s-.663,3.043-2.58,4.48-5.084,1.268-5.084,1.268L27.1,53.781Z" transform="matrix(0.998, -0.07, 0.07, 0.998, 905.509, 308.661)" fill="#d8dbe2" />
        <path id="Path_6979" data-name="Path 6979" d="M11.495,0V14.643H6.432a9.492,9.492,0,0,0-4.653,2.09A7.982,7.982,0,0,0,0,21.213V60.351a10.2,10.2,0,0,0,1.779,5.06,11.279,11.279,0,0,0,4.653,3.01H47.621s3.816-.114,5.732-2.133,1.932-5.937,1.932-5.937V21.213s-.664-3.043-2.58-4.481-5.084-1.267-5.084-1.267l-20.527-.823Z" transform="translate(1009.712 391.504) rotate(176)" fill="#d8dbe2" />
        <g id="Group_6362" data-name="Group 6362" transform="translate(911.937 303.365)">
          <path id="Path_6973" data-name="Path 6973" d="M-8825.951-22771.211v-14.643h-5.063a9.5,9.5,0,0,1-4.652-2.09,7.976,7.976,0,0,1-1.779-4.48v-39.139a10.2,10.2,0,0,1,1.779-5.061,11.284,11.284,0,0,1,4.652-3.012h41.19s3.816.115,5.732,2.135,1.932,5.938,1.932,5.938v39.139s-.663,3.043-2.58,4.48-5.084,1.268-5.084,1.268l-20.526.822Z" transform="translate(8837.446 22839.635)" style="fill: rgb(var(--una-primary-600))" />
          <g id="Group_5777" data-name="Group 5777" transform="translate(13.38 18.756)">
            <path id="Path_1949" data-name="Path 1949" d="M753.364,341.651s-9.261-4.806-12.631-11.5.2-11.814,4.223-13.276,12.14-2.152,17.459.324,5.968,8.4,3.613,12.465-7.876,6.739-9.947,7.47a23.392,23.392,0,0,1-2.716.853Z" transform="translate(-737.835 -314.236)" fill="#fff" />
            <g id="Group_3827" data-name="Group 3827">
              <path id="Path_1948" data-name="Path 1948" d="M2923.4-19983.809v8.352l3.722-1.811Z" transform="translate(-2907.804 19999.627)" fill="#d8dbe2" />
              <path id="Path_1947" data-name="Path 1947" d="M2856.429-20049.523a30.615,30.615,0,0,1-9.4-5.785c-4.114-3.889-5.618-6.656-6.107-10.486a9.2,9.2,0,0,1,2.894-8.379c3.228-3.039,7.84-3.559,12.559-3.445s8.421.078,11.985,3.445a10.36,10.36,0,0,1,3.115,7.754c-.074,3.043-1.945,6.645-5.443,9.332a37.926,37.926,0,0,1-9.6,5.5v-2.131s13.275-4.906,13.274-13.234a8.485,8.485,0,0,0-3.042-6.434c-2.816-2.262-5.856-2.266-10.285-2.393-4.193.049-7.942.313-10.6,2.5a8,8,0,0,0-2.9,6.42c.029,3.2,1.967,6.561,5.5,9.941a31.767,31.767,0,0,0,8.047,5.367Z" transform="translate(-2840.826 20077.633)" fill="url(#linear-gradient)" />
              <rect id="Rectangle_2722" data-name="Rectangle 2722" width="20.34" height="10.315" rx="5.157" transform="translate(5.247 5.502)" fill="#d8dbe2" />
              <rect id="Rectangle_2721" data-name="Rectangle 2721" width="17.048" height="7.564" rx="3.782" transform="translate(7.005 6.898)" fill="#2f385b" />
              <circle id="Ellipse_826" data-name="Ellipse 826" cx="1.935" cy="1.935" r="1.935" transform="translate(10.194 8.714)" fill="#1560f6" />
              <circle id="Ellipse_827" data-name="Ellipse 827" cx="1.935" cy="1.935" r="1.935" transform="translate(17.125 8.714)" fill="#1560f6" />
              <rect id="Rectangle_2724" data-name="Rectangle 2724" width="3.324" height="0.475" transform="translate(2.034 10.382)" fill="#d8dbe2" />
              <rect id="Rectangle_2725" data-name="Rectangle 2725" width="3.561" height="0.475" transform="translate(25.301 10.382)" fill="#d8dbe2" />
            </g>
          </g>
          <path id="Path_6975" data-name="Path 6975" d="M7.067-.7a.981.981,0,0,1,.173.518.679.679,0,0,1-.274.554.983.983,0,0,1-.63.219A.938.938,0,0,1,5.878.473a.975.975,0,0,1-.356-.34L4.912-.894a.59.59,0,0,0-.274-.249,1.278,1.278,0,0,0-.5-.076,3.84,3.84,0,0,1-1.906-.452A3.086,3.086,0,0,1,.989-2.952,4.114,4.114,0,0,1,.552-4.9,4.114,4.114,0,0,1,.989-6.845,3.054,3.054,0,0,1,2.234-8.121,3.875,3.875,0,0,1,4.14-8.568a3.875,3.875,0,0,1,1.906.447,3.023,3.023,0,0,1,1.24,1.276A4.154,4.154,0,0,1,7.718-4.9a4.162,4.162,0,0,1-.432,1.941A3.008,3.008,0,0,1,6.041-1.677a1.374,1.374,0,0,1,.813.62ZM4.14-2.653A1.534,1.534,0,0,0,5.41-3.227,2.636,2.636,0,0,0,5.868-4.9a2.593,2.593,0,0,0-.462-1.667A1.543,1.543,0,0,0,4.14-7.135a1.545,1.545,0,0,0-1.271.564A2.61,2.61,0,0,0,2.412-4.9a2.632,2.632,0,0,0,.457,1.677A1.539,1.539,0,0,0,4.14-2.653Zm8.172-1.992a.58.58,0,0,1-.427-.173.58.58,0,0,1-.173-.427.6.6,0,0,1,.173-.432.572.572,0,0,1,.427-.178h3.761a.58.58,0,0,1,.427.173.58.58,0,0,1,.173.427.6.6,0,0,1-.173.432.572.572,0,0,1-.427.178Zm0,2.033a.58.58,0,0,1-.427-.173.58.58,0,0,1-.173-.427.6.6,0,0,1,.173-.432.572.572,0,0,1,.427-.178h3.761a.58.58,0,0,1,.427.173.58.58,0,0,1,.173.427.6.6,0,0,1-.173.432.572.572,0,0,1-.427.178ZM27.6-2.459a.875.875,0,0,1,.091.376.727.727,0,0,1-.269.569.912.912,0,0,1-.615.234.783.783,0,0,1-.427-.122.823.823,0,0,1-.3-.366l-.457-1.027H22.323l-.468,1.027a.811.811,0,0,1-.31.366.809.809,0,0,1-.432.122.95.95,0,0,1-.625-.234.716.716,0,0,1-.28-.569.875.875,0,0,1,.091-.376l2.683-5.509a.944.944,0,0,1,.391-.422,1.141,1.141,0,0,1,.574-.147,1.15,1.15,0,0,1,.569.147.937.937,0,0,1,.4.422ZM24.986-4.2,23.97-6.464,22.954-4.2ZM29.4-1.25a.913.913,0,0,1-.676-.249.913.913,0,0,1-.249-.676V-7.613a.9.9,0,0,1,.249-.666.913.913,0,0,1,.676-.249.945.945,0,0,1,.691.249.89.89,0,0,1,.254.666v5.438a.9.9,0,0,1-.254.676A.945.945,0,0,1,29.4-1.25Z" transform="translate(12.828 15.128)" fill="#fff" />
        </g>
        <g id="Group_6361-2" data-name="Group 6361" transform="translate(952.352 316.29)">
          <path id="Path_6974" data-name="Path 6974" d="M-8793.655-22771.211v-14.643h5.063a9.5,9.5,0,0,0,4.653-2.09,7.976,7.976,0,0,0,1.779-4.48v-39.139a10.2,10.2,0,0,0-1.779-5.061,11.285,11.285,0,0,0-4.653-3.012h-41.189s-3.816.115-5.732,2.135-1.932,5.938-1.932,5.938v39.139s.663,3.043,2.58,4.48,5.084,1.268,5.084,1.268l20.526.822Z" transform="translate(8837.446 22845.465)" style="fill: rgb(var(--una-primary-400))" />
          <path id="Path_6976" data-name="Path 6976" d="M5.554-8.2a.869.869,0,0,1,.675-.332.927.927,0,0,1,.658.282A.874.874,0,0,1,7.18-7.6a.929.929,0,0,1-.243.608L4.614-4.307v2.7a.965.965,0,0,1-.282.747,1.027,1.027,0,0,1-.724.26,1.063,1.063,0,0,1-.741-.26.955.955,0,0,1-.288-.747v-2.7L.278-6.995a.919.919,0,0,1-.254-.619.864.864,0,0,1,.288-.642.917.917,0,0,1,.653-.277.869.869,0,0,1,.675.332L3.6-5.877ZM9.614-.579A3.411,3.411,0,0,1,8.038-.928a2.5,2.5,0,0,1-1.051-.99,2.955,2.955,0,0,1-.371-1.5,2.925,2.925,0,0,1,.371-1.5A2.483,2.483,0,0,1,8.032-5.9a3.448,3.448,0,0,1,1.582-.343A3.448,3.448,0,0,1,11.2-5.9a2.483,2.483,0,0,1,1.045.979,2.925,2.925,0,0,1,.371,1.5,2.955,2.955,0,0,1-.371,1.5,2.508,2.508,0,0,1-1.045.99A3.4,3.4,0,0,1,9.614-.579Zm0-1.471q1.051,0,1.051-1.372T9.614-4.793q-1.051,0-1.051,1.372T9.614-2.05Zm8.4-4.17a1.023,1.023,0,0,1,.719.243.856.856,0,0,1,.265.664v3.827a.817.817,0,0,1-.277.636A1.034,1.034,0,0,1,18-.6a.974.974,0,0,1-.653-.216A.754.754,0,0,1,17.08-1.4a1.731,1.731,0,0,1-.647.608,1.881,1.881,0,0,1-.9.21,1.966,1.966,0,0,1-1.56-.581,2.618,2.618,0,0,1-.509-1.764V-5.313a.856.856,0,0,1,.265-.664,1.023,1.023,0,0,1,.719-.243,1.023,1.023,0,0,1,.719.243.856.856,0,0,1,.265.664V-2.88q0,.8.686.8a.832.832,0,0,0,.653-.288,1.093,1.093,0,0,0,.254-.752v-2.19a.856.856,0,0,1,.265-.664A1.023,1.023,0,0,1,18.009-6.22Z" transform="translate(17.889 49.119)" fill="#fff" />
          <circle id="Ellipse_1592" data-name="Ellipse 1592" cx="2.659" cy="2.659" r="2.659" transform="translate(24.984 28.623)" fill="#fff" />
          <circle id="Ellipse_1593" data-name="Ellipse 1593" cx="2.659" cy="2.659" r="2.659" transform="translate(34.223 28.623)" fill="#fff" />
          <circle id="Ellipse_1594" data-name="Ellipse 1594" cx="2.659" cy="2.659" r="2.659" transform="translate(15.748 28.623)" fill="#fff" />
          <path id="Path_6980" data-name="Path 6980" d="M-8906.609-23000.24l-1.761-19.359s-.522-3.256,4.237-3.191,4.5,3.191,4.5,3.191l-2.086,19.359a2.361,2.361,0,0,1-2.411,2.021A2.456,2.456,0,0,1-8906.609-23000.24Z" transform="translate(8931.654 23022.791)" fill="#fff" style="stroke: rgb(var(--una-primary-600))" stroke-width="1" />
        </g>
      </g>
      <path id="Subtraction_74" data-name="Subtraction 74" d="M21895.648,1758.894a5,5,0,1,1,5-5A5.007,5.007,0,0,1,21895.648,1758.894Zm0-8.334a3.333,3.333,0,1,0,3.334,3.333A3.337,3.337,0,0,0,21895.648,1750.56Z" transform="translate(-20485.615 -1503.166)" fill="#34d188" />
      <path id="Icon_material-add" data-name="Icon material-add" d="M19.745,14.5H14.5v5.248H12.748V14.5H7.5V12.748h5.248V7.5H14.5v5.248h5.248Z" transform="translate(1300.497 310.498) rotate(45)" fill="#f59394" />
      <path id="Polygon_23" data-name="Polygon 23" d="M4.106,1.789a1,1,0,0,1,1.789,0L9.276,8.553A1,1,0,0,1,8.382,10H1.618A1,1,0,0,1,.724,8.553Z" transform="translate(1430.4 329.764) rotate(19)" fill="#ff8a3c" />
    </g>
  </svg>
</template>
