<script setup lang="ts">
const {
  cameras,
  currentCamera,
  microphones,
  currentMicrophone,
} = useMediaSettings()

const { storage, toggleSectionMediaSettings } = useRoomSettings()
const preferences = storage.value.preferences
</script>

<template>
  <div class="relative h-full w-full overflow-auto px-1 py-2 space-y-12">
    <div class="flex items-center justify-between">
      <h4 class="text-lg font-semibold">
        Configure Media
      </h4>

      <GButton
        label="i-close"
        icon
        btn="text-gray"
        class="p-0"
        @click="toggleSectionMediaSettings(false)"
      />
    </div>

    <div class="flex flex-col space-y-4">
      <h5 class="font-semibold">
        Video Settings
      </h5>
      <div class="flex flex-col gap-y-3">
        <GFormGroup
          label="Camera Device"
        >
          <CommonSelect
            v-model="currentCamera"
            option-attribute="label"
            value-attribute="deviceId"
            placeholder="Select Camera Device"
            :options="cameras"
          />
        </GFormGroup>
      </div>

      <label class="flex items-center">
        <GSwitch
          v-model:checked="preferences.isCamera"
        />

        <span class="ml-2 text-sm text-muted">
          Enable Camera
        </span>
      </label>

      <label class="flex items-center">
        <GSwitch
          v-model:checked="preferences.isMirror"
        />

        <span class="ml-2 text-sm text-muted">
          Mirror Camera
        </span>
      </label>

      <label class="flex items-center">
        <GSwitch
          v-model:checked="preferences.isVirtualBackground"
        />

        <span class="ml-2 text-sm text-muted">
          Virtual Background
        </span>
      </label>

      <div v-if="preferences.isVirtualBackground">
        <CommonVirtualBackgroundSelect
          v-model="preferences.cameraVirtualBackground"
        />
      </div>
    </div>

    <div class="flex flex-col space-y-4">
      <h5 class="font-semibold">
        Audio Settings
      </h5>

      <div class="flex flex-col gap-y-3">
        <GFormGroup
          label="Microphone Device"
        >
          <CommonSelect
            v-model="currentMicrophone"
            option-attribute="label"
            value-attribute="deviceId"
            placeholder="Select Microphone Device"
            :options="microphones"
          />
        </GFormGroup>
      </div>

      <label class="flex items-center">
        <GSwitch
          v-model:checked="preferences.isMicrophone"
        />

        <span class="ml-2 text-sm text-muted">
          Enable Microphone
        </span>
      </label>
    </div>
  </div>
</template>
