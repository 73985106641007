<script setup lang="ts">
const props = withDefaults(defineProps<{
  modelValue?: string
  options?: { src: string, value: string }[]
}>(), {
  options: () => ([
    {
      src: '/images/virtual-backgrounds/system-blur.svg',
      value: 'system-blur',
    },
    {
      src: '/images/virtual-backgrounds/1.png',
      value: '1',
    },
    {
      src: '/images/virtual-backgrounds/2.png',
      value: '2',
    },
    {
      src: '/images/virtual-backgrounds/3.png',
      value: '3',
    },
    {
      src: '/images/virtual-backgrounds/4.png',
      value: '4',
    },
    // add more src backgrounds here
  ]),
})

const emit = defineEmits(['update:modelValue'])
const value = useVModel(props, 'modelValue', emit)

function onSelect(optionValue: string) {
  emit('update:modelValue', optionValue)
}
</script>

<template>
  <div class="flex gap-4">
    <GAvatar
      v-for="option in options"
      :key="option.value"
      :src="option.src"
      ring="~ base"
      size="xl"
      :class="{ 'ring-2 ring-primary scale-110': value === option.value }"
      class="cursor-pointer rounded-md object-center transition-base"
      @click="onSelect(option.value)"
    />
  </div>
</template>
