<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="317.532" height="126.041" viewBox="0 0 317.532 126.041">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_3639" data-name="Rectangle 3639" width="221.687" height="125.111" fill="#fff" stroke="#707070" stroke-width="1" />
      </clipPath>
    </defs>
    <g id="Group_6345" data-name="Group 6345" transform="translate(-1214.5 -213)">
      <g id="Mask_Group_6101" data-name="Mask Group 6101" transform="translate(1259.496 213)" clip-path="url(#clip-path)">
        <circle id="Ellipse_1518" data-name="Ellipse 1518" cx="98.772" cy="98.772" r="98.772" transform="translate(15.364 29.997)" style="fill: rgb(var(--una-primary-600))" opacity="0.1" />
      </g>
      <g id="Group_6264" data-name="Group 6264" transform="translate(1214.5 338.111)">
        <line id="Line_1795" data-name="Line 1795" x2="317.532" fill="none" stroke="#e8e9ee" stroke-width="1" />
      </g>
      <g id="Group_6297" data-name="Group 6297" transform="matrix(0.891, -0.454, 0.454, 0.891, 1312.773, 289.851)">
        <rect id="Rectangle_3640" data-name="Rectangle 3640" width="2.195" height="8.78" rx="1" transform="matrix(-0.966, 0.259, -0.259, -0.966, 15.676, 8.481)" style="fill: rgb(var(--una-primary-600))" />
        <rect id="Rectangle_3641" data-name="Rectangle 3641" width="2.195" height="8.78" rx="1" transform="translate(9.973 10.038) rotate(135)" style="fill: rgb(var(--una-primary-600))" />
        <rect id="Rectangle_3642" data-name="Rectangle 3642" width="2.195" height="8.78" rx="1" transform="matrix(0.017, 1, -1, 0.017, 8.778, 14.666)" style="fill: rgb(var(--una-primary-600))" />
      </g>
      <g id="webcam-svgrepo-com" transform="translate(1280.571 299.125)">
        <path id="Path_6953" data-name="Path 6953" d="M226.194,319.8a1.945,1.945,0,0,1-1.9,1.9h0a1.9,1.9,0,0,1-1.9-1.9v-7.9a1.945,1.945,0,0,1,1.9-1.9h0a1.9,1.9,0,0,1,1.9,1.9Z" transform="translate(-132.617 -285.495)" fill="#e8e9ee" />
        <path id="Path_6954" data-name="Path 6954" d="M246.4,310h0a1.9,1.9,0,0,1,1.9,1.9v7.968a1.9,1.9,0,0,1-1.9,1.9h0" transform="translate(-154.72 -285.495)" fill="#bdc1cc" />
        <circle id="Ellipse_1577" data-name="Ellipse 1577" cx="13.28" cy="13.28" r="13.28" transform="translate(78.4 0.032)" style="fill: rgb(var(--una-primary-400))" />
        <path id="Path_6955" data-name="Path 6955" d="M224.3,310a1.9,1.9,0,0,0-1.9,1.9v3.352c.632.063,1.265.126,1.9.126s1.265-.063,1.9-.126V311.9A1.9,1.9,0,0,0,224.3,310Z" transform="translate(-132.617 -285.495)" fill="#9ca0b1" />
        <path id="Path_6956" data-name="Path 6956" d="M145.982,286.8a13.282,13.282,0,0,1-18.782,0" transform="translate(-44.942 -264.129)" style="fill: rgb(var(--una-primary-600))" />
        <path id="Path_6957" data-name="Path 6957" d="M128,3.889a13.281,13.281,0,0,1,18.782,18.782" transform="translate(-45.679 0)" style="fill: rgb(var(--una-primary-600))" />
        <circle id="Ellipse_1578" data-name="Ellipse 1578" cx="7.652" cy="7.652" r="7.652" transform="translate(84.028 6.925)" style="fill: rgb(var(--una-primary-500))" />
        <circle id="Ellipse_1579" data-name="Ellipse 1579" cx="7.652" cy="7.652" r="7.652" transform="translate(84.028 5.66)" fill="#363f3e" />
        <path id="Path_6958" data-name="Path 6958" d="M177.6,73.629a7.691,7.691,0,1,1,10.877,10.877" transform="translate(-91.359 -65.756)" fill="#151918" />
        <circle id="Ellipse_1580" data-name="Ellipse 1580" cx="5.312" cy="5.312" r="5.312" transform="translate(86.368 8)" fill="#34d188" />
        <path id="Path_6959" data-name="Path 6959" d="M179.2,106.512a5.312,5.312,0,1,1,10.624.063" transform="translate(-92.832 -93.2)" fill="#13a762" />
        <circle id="Ellipse_1581" data-name="Ellipse 1581" cx="3.605" cy="3.605" r="3.605" transform="translate(88.075 9.707)" fill="#13a762" />
        <path id="Path_6960" data-name="Path 6960" d="M246.4,122a3.6,3.6,0,0,1,3.6,3.6,3.644,3.644,0,0,1-3.6,3.6" transform="translate(-154.72 -112.356)" fill="#34d188" />
        <circle id="Ellipse_1582" data-name="Ellipse 1582" cx="0.885" cy="0.885" r="0.885" transform="translate(90.795 2.308)" fill="#ff48a4" />
        <path id="Path_6961" data-name="Path 6961" d="M237.639,33.6a.85.85,0,0,1-1.2-1.2" transform="translate(-145.326 -29.839)" fill="#ef187a" />
        <g id="Group_6360" data-name="Group 6360" transform="translate(89.214 10.719)">
          <circle id="Ellipse_1583" data-name="Ellipse 1583" cx="0.822" cy="0.822" r="0.822" transform="translate(0)" fill="#64ffe8" />
          <circle id="Ellipse_1584" data-name="Ellipse 1584" cx="0.126" cy="0.126" r="0.126" transform="translate(0.949 2.719)" fill="#7deada" />
          <circle id="Ellipse_1585" data-name="Ellipse 1585" cx="0.19" cy="0.19" r="0.19" transform="translate(1.771 1.96)" fill="#7deada" />
          <circle id="Ellipse_1586" data-name="Ellipse 1586" cx="0.822" cy="0.822" r="0.822" transform="translate(4.49 3.668)" fill="#64ffe8" />
        </g>
        <path id="Path_6962" data-name="Path 6962" d="M131.227,431.312c-1.771-3.162-5.944-5.312-10.814-5.312s-9.043,2.15-10.814,5.312Z" transform="translate(-28.734 -392.326)" style="fill: rgb(var(--una-primary-400))" />
        <path id="Path_6963" data-name="Path 6963" d="M168.065,431.312c-1.771-3.162-5.944-5.312-10.814-5.312s-5.944,2.15-7.652,5.312Z" transform="translate(-65.572 -392.326)" style="fill: rgb(var(--una-primary-600))" />
      </g>
      <g id="headphone-svgrepo-com" transform="translate(1316.246 234.614)">
        <path id="Path_6964" data-name="Path 6964" d="M11.755.641a.64.64,0,0,0-.95-.561L3.414,4.161a.64.64,0,0,0-.331.561v8.608a2.18,2.18,0,1,0,1.274,2.045.52.52,0,0,0,.007-.062V7.806l6.111-3.374v4.6a2.179,2.179,0,1,0,1.281,1.984c0-.012,0-.024,0-.037s0-.012,0-.018V.641Z" transform="matrix(0.999, -0.052, 0.052, 0.999, 26.712, -11.584)" fill="#34d188" />
        <path id="Path_6965" data-name="Path 6965" d="M7.386.062,3.894,1.99a.731.731,0,0,0-.378.64v9.819A2.486,2.486,0,1,0,4.97,14.78a.58.58,0,0,0,.008-.07V6.437a.489.489,0,0,1,.253-.428L7.859,4.557a.489.489,0,0,0,.253-.428V.49A.489.489,0,0,0,7.386.062Z" transform="translate(101.935 5.238) rotate(41)" fill="#ff8a3c" />
        <path id="Path_6966" data-name="Path 6966" d="M164.9,67.225a3.1,3.1,0,0,1-1.678-5.718,32.766,32.766,0,0,1,35.119.015,3.1,3.1,0,0,1-3.4,5.194,26.468,26.468,0,0,0-28.369.018A3.089,3.089,0,0,1,164.9,67.225Z" transform="translate(-124.692 -41.023)" fill="#2f385b" />
        <path id="Path_6967" data-name="Path 6967" d="M139.467,80.394a3.554,3.554,0,0,1-3.554-3.554,28.076,28.076,0,0,0-56.152,0,3.554,3.554,0,0,1-7.108,0,35.184,35.184,0,0,1,70.368,0A3.554,3.554,0,0,1,139.467,80.394Z" transform="translate(-51.921 -28.976)" fill="#9ca0b1" />
        <path id="Path_6968" data-name="Path 6968" d="M86.592,251.294a1.219,1.219,0,0,1-1.219-1.219V227.852a1.218,1.218,0,1,1,2.437,0v22.223a1.218,1.218,0,0,1-1.219,1.219Zm64.478-1.219V227.852a1.219,1.219,0,1,0-2.437,0v22.223a1.219,1.219,0,0,0,2.437,0Z" transform="translate(-62.305 -179.989)" fill="#bdc1cc" />
        <path id="Path_6969" data-name="Path 6969" d="M76.46,263.524a13.539,13.539,0,0,0,0,27.077h4.019V263.524Z" transform="translate(-43.976 -210.105)" fill="#d8dbe2" />
        <path id="Path_6970" data-name="Path 6970" d="M376.377,263.524h-4.019V290.6h4.019a13.539,13.539,0,0,0,0-27.077Z" transform="translate(-296.594 -210.105)" fill="#d8dbe2" />
        <path id="Path_6971" data-name="Path 6971" d="M60.361,393.4a7.37,7.37,0,0,1-1.914-.249,6.63,6.63,0,0,1-4.726-4.747c-.4-1.5-.864-5.451,3.494-9.81.494-.494.945-.918,1.344-1.293,2.28-2.142,2.969-2.789,2.969-8.6a1.218,1.218,0,1,1,2.437,0c0,6.6-1.079,7.879-3.737,10.376-.386.362-.823.773-1.29,1.24-3.46,3.46-3.155,6.367-2.864,7.456a4.165,4.165,0,0,0,3,3.024c2.286.613,5.044-.483,7.567-3.005,3.726-3.726,8.108-5.1,11.72-3.666a7.54,7.54,0,0,1,4.711,6.939,1.218,1.218,0,1,1-2.436.076,5.073,5.073,0,0,0-3.173-4.749c-2.68-1.062-6.082.106-9.1,3.123C65.836,392.05,63.007,393.4,60.361,393.4Z" transform="matrix(0.998, 0.07, -0.07, 0.998, -10.56, -293.814)" fill="#bdc1cc" />
        <path id="Path_6972" data-name="Path 6972" d="M101.12,233.422v27.237a1.2,1.2,0,0,1-1.2,1.2h-2.04a1.2,1.2,0,0,1-1.2-1.2V233.422a1.2,1.2,0,0,1,1.2-1.2h2.04A1.2,1.2,0,0,1,101.12,233.422Zm38.064-1.2h-2.04a1.2,1.2,0,0,0-1.2,1.2v27.237a1.2,1.2,0,0,0,1.2,1.2h2.04a1.2,1.2,0,0,0,1.2-1.2V233.422a1.2,1.2,0,0,0-1.2-1.2Zm10.763-5.475a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,149.946,226.749Zm-63.26,0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,86.686,226.749Z" transform="translate(-62.4 -180.083)" fill="#535a78" />
      </g>
    </g>
  </svg>
</template>
