<script setup lang="ts">
const { room } = useRoomStore()
const [DefineTemplate, ReuseTemplate] = createReusableTemplate()

const { toggleSectionJobDetails } = useRoomSettings()
</script>

<template>
  <DefineTemplate>
    <GIcon
      name="i-ph-arrow-square-out"
      class="absolute right-3 top-3 cursor-pointer hover:text-accent text-muted"
    />
  </DefineTemplate>

  <section class="overflow-auto py-2 space-y-6">
    <div class="mb-5 flex justify-between">
      <h5>
        Application Details
      </h5>

      <GButton
        label="i-close"
        icon
        btn="text-gray"
        class="p-0"
        @click="toggleSectionJobDetails(false)"
      />
    </div>

    <div class="flex flex-col space-y-2">
      <h6>
        Your Profile
      </h6>
      <div
        card="~ padded"
        border="~ padded"
        class="relative flex items-center"
      >
        <ReuseTemplate />

        <GAvatar
          class="mr-3"
          :label="getInitials(room?.applicantName!)" :src="room?.imagekitUrl"
        />
        <div class="flex grow flex-col leading-4">
          <span class="text-sm font-medium capitalize">{{ room?.applicantName }}</span>
          <span class="text-xs text-muted">UI/UX Designer</span>
        </div>
      </div>
    </div>

    <div class="flex flex-col space-y-2">
      <h6>
        Job Details
      </h6>
      <div
        card="~ padded"
        border="~ padded"
        class="relative"
      >
        <ReuseTemplate />

        <CommonSourceLogo :source="room?.jobPost?.source" />

        <div class="mt-1 space-y-2">
          <div>
            <span class="text-xs text-muted">
              Job tittle
            </span>
            <p class="text-sm">
              {{ room?.jobTitle }}
            </p>
          </div>

          <div>
            <span class="text-xs text-muted">
              Date posted
            </span>
            <p class="text-sm">
              {{ useTimeAgo(room?.jobPost?.createdAt!).value }}
            </p>
          </div>

          <div>
            <span class="text-xs text-muted">
              Description
            </span>
            <p class="relative relative max-h-200px overflow-y-auto whitespace-normal text-justify text-sm">
              {{ room?.jobPost?.description }}
              <CommonFadeOutDiv />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
~/utils/getInitials
