<script setup lang="ts">
import { GButton } from '#components'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps <{
  id?: string
  modelValue?: any
  valueAttribute: string
  options: any[]
  placeholder?: string
  optionAttribute: string
  defaultValue?: any

  required?: boolean
  isLoading?: boolean
  isDisabled?: boolean
}> (), {
  id: 'id',
  modelValue: null,
  options: () => ([]),
  placeholder: 'Select an option',
  optionAttribute: 'label',
  defaultValue: null,

  required: false,
  isLoading: false,
  isDisabled: false,
})

// set emits
const emit = defineEmits(['update:modelValue', 'blur', 'input'])
const value = useVModel(props, 'modelValue', emit)

const label = computed(() => {
  if (props.modelValue !== undefined && props.modelValue !== null) {
    if (props.valueAttribute) {
      const option = props.options.find(option => option[props.valueAttribute] === props.modelValue)
      return option ? option[props.optionAttribute] : null
    }
    else {
      return ['string', 'number'].includes(typeof props.modelValue) ? props.modelValue : props.modelValue[props.optionAttribute]
    }
  }

  return null
})
</script>

<template>
  <Listbox v-model="value" as="div" :default-value="defaultValue">
    <div class="relative">
      <ListboxButton
        v-bind="$attrs"
        :id="id"
        :as="GButton"
        btn="solid-white"
        class="relative h-2.5em w-full justify-between px-2.5 font-normal"
        trailing="i-ph-caret-up-down"
        :disabled="isDisabled"
      >
        <span v-if="!value || !label" class="text-muted">{{ placeholder }}</span>
        <span v-else class="block truncate">{{ label }}</span>
      </ListboxButton>

      <Transition
        leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-muted py-1 text-sm shadow-lg ring-1 ring-base focus:outline-none"
        >
          <ListboxOption
            v-for="(option, i) in props.options" :key="i" v-slot="{ active, selected }" as="template"
            :value="valueAttribute ? option[valueAttribute] : option"
          >
            <li
              class="relative cursor-pointer select-none py-2 pl-3 pr-9"
              :class="[active ? 'text-inverted bg-primary' : 'text-muted']"
            >
              <span class="block truncate capitalize" :class="[selected ? 'font-semibold' : 'font-normal']">{{ ['string', 'number'].includes(typeof option) ? option : option[optionAttribute] }}</span>

              <span
                v-if="selected"
                class="absolute inset-y-0 right-0 flex items-center pr-4"
                :class="[active ? 'text-inverted' : 'text-primary']"
              >
                <GIcon name="i-check" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </Transition>
    </div>
  </Listbox>
</template>
