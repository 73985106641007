<script setup lang="ts">
const preferences = useRoomSettings().storage.value.preferences
</script>

<template>
  <div class="relative">
    <RoomSectionFaqs v-if="preferences.isSectionFaqs" />
    <RoomSectionJobDetails v-if="preferences.isSectionJobDetails" />
  </div>
</template>
