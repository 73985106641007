export const FAQS: {
  label: string
  content: string
}[] = [
  {
    label: 'How does InterviewRoom works?',
    content: 'InterviewRoom simulates the initial interview phase by using AI to emulate a recruiter\'s role. The AI engages you in a conversation, asking questions relevant to the interview. Your responses are recorded and later analyzed for further assessment.',
  },
  {
    label: 'Can I have a conversation with the AI?',
    content: 'Currently, the AI takes on the role of an interviewer and poses questions for you to answer. You can\'t engage in a two-way conversation, but your answers contribute to the analysis.',
  },
  {
    label: 'Is there a time limit for my answer?',
    content: 'There is no specific time limit for your responses, but there is a timer that counts down before you provide an answer to each question.',
  },
  {
    label: 'Can I repeat or re-do my answer?',
    content: 'Unfortunately, no. The process aims to replicate a genuine initial interview, so once you\'ve provided an answer, it cannot be repeated or changed.',
  },
  {
    label: 'Can I add more answers to a single question?',
    content: 'You can provide multiple responses to a single question without a time limit. However, all your answers for that question are considered collectively during the analysis.',
  },
  {
    label: 'Can I stop recording my answers?',
    content: 'Pausing the recording of your answers isn\'t possible, but you can exit the session and return to it later.',
  },
  {
    label: 'What if I get disconnected from the room?',
    content: 'In case of disconnection, you can simply use the provided link to rejoin the interview room and resume where you left off.',
  },
  {
    label: 'What if I end the interview halfway?',
    content: 'If you decide to end the interview prematurely, the analysis might not be as comprehensive since it\'s based on the entire interview. It\'s advisable to complete the full interview for a more accurate evaluation.',
  },
  {
    label: 'What is the next step after the interview completion?',
    content: 'Once you\'ve completed the interview, our human team will review the results. If you\'re considered a potential fit, we\'ll get in touch with you to discuss the next steps. The subsequent process can vary depending on the specific position you\'re applying for.',
  },
]
