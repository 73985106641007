<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="317.532" height="125.611" viewBox="0 0 317.532 125.611">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_3639" data-name="Rectangle 3639" width="221.687" height="125.111" fill="#fff" stroke="#707070" stroke-width="1" />
      </clipPath>
    </defs>
    <g id="Group_6359" data-name="Group 6359" transform="translate(-1346.233 -261.91)">
      <g id="Group_6268" data-name="Group 6268" transform="translate(131.733 48.91)">
        <g id="Mask_Group_6101" data-name="Mask Group 6101" transform="translate(1259.496 213)" clip-path="url(#clip-path)">
          <circle id="Ellipse_1518" data-name="Ellipse 1518" cx="98.772" cy="98.772" r="98.772" transform="translate(15.364 29.997)" style="fill: rgb(var(--una-primary-600))" opacity="0.1" />
        </g>
        <g id="Group_6264" data-name="Group 6264" transform="translate(1214.5 338.111)">
          <line id="Line_1795" data-name="Line 1795" x2="317.532" fill="none" stroke="#e8e9ee" stroke-width="1" />
        </g>
        <g id="Group_6297" data-name="Group 6297" transform="matrix(-0.259, 0.966, -0.966, -0.259, 1439.865, 257.907)">
          <rect id="Rectangle_3640" data-name="Rectangle 3640" width="2.195" height="8.78" rx="1" transform="matrix(-0.966, 0.259, -0.259, -0.966, 15.676, 8.481)" style="fill: rgb(var(--una-primary-600))" />
          <rect id="Rectangle_3641" data-name="Rectangle 3641" width="2.195" height="8.78" rx="1" transform="translate(9.973 10.038) rotate(135)" style="fill: rgb(var(--una-primary-600))" />
          <rect id="Rectangle_3642" data-name="Rectangle 3642" width="2.195" height="8.78" rx="1" transform="matrix(0.017, 1, -1, 0.017, 8.778, 14.666)" style="fill: rgb(var(--una-primary-600))" />
        </g>
        <g id="Group_6346" data-name="Group 6346" transform="translate(-144.946 -76.461)">
          <path id="Path_6945" data-name="Path 6945" d="M-8276.237-22774.826l-1.732-31.717a11.507,11.507,0,0,1,2.382-5.3,9.813,9.813,0,0,1,4.979-2.383h29.983a12.285,12.285,0,0,1,4.221.65,8.978,8.978,0,0,1,3.141,2.273l10.715,7.145h30.2s3.789.135,5.846,2.057,2.382,5.629,2.382,5.629l-2.382,21.648Z" transform="translate(9750.974 23146.527)" fill="#bdc1cc" />
          <g id="Group_6346-2" data-name="Group 6346" transform="translate(1483.723 338.043)">
            <path id="Path_6943" data-name="Path 6943" d="M-8268.169-22778.477v-19.92l11.041-10.281h55.42s2.9,0,4.33,1.516,1.407,4.545,1.407,4.545v24.141Z" transform="translate(8268.169 22809.002)" fill="#fff" />
            <path id="Path_6944" data-name="Path 6944" d="M-8268.169-22798.26h6.277s2.518.3,3.789-.865,1.3-3.789,1.3-3.789v-6.062Z" transform="translate(8268.169 22808.977)" fill="#d8dbe2" />
          </g>
          <path id="Icon_awesome-play-circle" data-name="Icon awesome-play-circle" d="M9.525.563a8.963,8.963,0,1,0,8.963,8.963A8.961,8.961,0,0,0,9.525.563Zm4.181,9.83-6.361,3.65a.869.869,0,0,1-1.29-.759V5.767a.869.869,0,0,1,1.29-.759l6.361,3.867A.87.87,0,0,1,13.707,10.393Z" transform="translate(1511.975 350.082)" fill="#ff4e66" />
          <path id="Path_6942" data-name="Path 6942" d="M-8276.732-22734.4l-5.5-39.508a8.307,8.307,0,0,1,1.658-5.8,9.35,9.35,0,0,1,5.293-3.342h37.684a9.718,9.718,0,0,0,4.112-.748,8.946,8.946,0,0,0,3.022-2.729l4.208-4.754a8.088,8.088,0,0,1,2.619-1.947,9.835,9.835,0,0,1,3.417-.615h35.489a9.107,9.107,0,0,1,4.153,2.563,10.124,10.124,0,0,1,1.7,4.754l-5.854,52.131h-92Z" transform="translate(9750.57 23148.436)" fill="#d8dbe2" />
          <path id="Path_6946" data-name="Path 6946" d="M-8280.709-22756.432s-24.773-25.354-16.735-50.393,32.462-33.082,32.462-33.082" transform="translate(9749.001 23144.123)" fill="none" stroke="#535a78" stroke-width="1" stroke-dasharray="4 2" />
          <g id="Group_6348" data-name="Group 6348" transform="translate(1480.257 291.127)">
            <path id="Path_6949" data-name="Path 6949" d="M-8266.465-22834.252l.832-11.406,27.477-5Z" transform="translate(8271.794 22851.986)" style="fill: rgb(var(--una-primary-600))" />
            <path id="Path_6947" data-name="Path 6947" d="M-8265.01-22845.545l-6.328-6.33,33.723,1.334Z" transform="translate(8271.338 22851.875)" style="fill: rgb(var(--una-primary-400))" />
            <path id="Path_6948" data-name="Path 6948" d="M-8262.888-22842.51l24.23-7.994-16.985,15.82Z" transform="translate(8272.13 22852.004)" style="fill: rgb(var(--una-primary-400))" />
            <path id="Path_6950" data-name="Path 6950" d="M-8258.972-22839.273l-3.6-3.99-3.892,8.277Z" transform="translate(8271.795 22852.682)" style="fill: rgb(var(--una-primary-500))" />
          </g>
          <path id="Icon_awesome-arrow-alt-circle-down" data-name="Icon awesome-arrow-alt-circle-down" d="M18.488,9.525A8.963,8.963,0,1,1,9.525.563,8.961,8.961,0,0,1,18.488,9.525ZM7.935,5.333V9.525H5.373a.434.434,0,0,0-.307.741L9.218,14.4a.43.43,0,0,0,.611,0l4.153-4.131a.434.434,0,0,0-.307-.741H11.115V5.333a.435.435,0,0,0-.434-.434H8.369A.435.435,0,0,0,7.935,5.333Z" transform="translate(1511.975 380.887)" style="fill: rgb(var(--una-primary-600))" />
        </g>
      </g>
      <path id="Subtraction_73" data-name="Subtraction 73" d="M21895.648,1758.894a5,5,0,1,1,5-5A5.007,5.007,0,0,1,21895.648,1758.894Zm0-8.334a3.333,3.333,0,1,0,3.334,3.333A3.337,3.337,0,0,0,21895.648,1750.56Z" transform="translate(-20373.652 -1473.277)" fill="#34d188" />
      <path id="Icon_material-add" data-name="Icon material-add" d="M19.745,14.5H14.5v5.248H12.748V14.5H7.5V12.748h5.248V7.5H14.5v5.248h5.248Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 1436.365, 351.967)" fill="#fa4d95" />
    </g>
  </svg>
</template>
