<script setup lang="ts">
interface Source {
  alt: string
  url: string
}

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  source?: 'r24' | 'greenhouse' | 'odoo' | null
}>()

const src = computed<Source>(() => {
  const sourceMap = {
    r24: { alt: 'R24', url: '/svgs/multiplai-r24-icon.svg' },
    greenhouse: { alt: 'Greenhouse', url: '/svgs/greenhouse-icon.svg' },
    odoo: { alt: 'Odoo', url: '/svgs/odoo-icon.svg' },
    default: { alt: 'Multiplai Interviews', url: '/svgs/multiplai-interviews-icon.svg' },
  }

  return sourceMap[props.source!] || sourceMap.default
})
</script>

<template>
  <GAvatar
    avatar="~"
    :alt="src.alt"
    class="rounded-none"
    :src="src.url"
    fallback="/svgs/multiplai-interviews-icon.svg"
  />
</template>
