<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="163.253" height="123.113" viewBox="0 0 163.253 123.113">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" y1="-0.038" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ff4e66" />
        <stop offset="0.284" stop-color="#ff3386" />
        <stop offset="1" stop-color="#7e17a3" />
      </linearGradient>
    </defs>
    <g id="undraw_group_video_re_btu7" transform="translate(0.024 3)">
      <path id="Path_6644" data-name="Path 6644" d="M330.462,341.963h-95.9a3.987,3.987,0,0,1-3.983-3.983v-68.8a3.987,3.987,0,0,1,3.983-3.983h95.9a3.987,3.987,0,0,1,3.983,3.983v68.8A3.987,3.987,0,0,1,330.462,341.963Zm-95.9-74.669a1.889,1.889,0,0,0-1.887,1.887v68.8a1.889,1.889,0,0,0,1.887,1.887h95.9a1.889,1.889,0,0,0,1.887-1.887v-68.8a1.889,1.889,0,0,0-1.887-1.887Z" transform="translate(-229.445 -245.394)" fill="#d8dbe2" />
      <circle id="Ellipse_1377" data-name="Ellipse 1377" cx="7.716" cy="7.716" r="7.716" transform="translate(43.316 104.681)" fill="#d8dbe2" />
      <circle id="Ellipse_1378" data-name="Ellipse 1378" cx="5.915" cy="5.915" r="5.915" transform="translate(45.117 106.482)" fill="#fff" />
      <path id="Path_6645" data-name="Path 6645" d="M451.016,681.931a.268.268,0,0,1-.19-.079l-5.927-5.927a.269.269,0,0,1,.381-.381l5.927,5.927a.269.269,0,0,1-.19.46Z" transform="translate(-397.021 -566.304)" style="fill: rgb(var(--una-primary-600))" />
      <path id="Path_6646" data-name="Path 6646" d="M456.267,682.195a.542.542,0,0,0,0-.585,5.707,5.707,0,0,0-1.672-1.693,4.236,4.236,0,0,0-2.289-.708,3.813,3.813,0,0,0-1.209.2.067.067,0,0,0-.026.112l.8.8a.068.068,0,0,0,.065.018,1.617,1.617,0,0,1,1.953,1.188l0,.008a3.552,3.552,0,0,0,.907,1.713l.253.254a.068.068,0,0,0,.091,0A5.792,5.792,0,0,0,456.267,682.195Z" transform="translate(-401.89 -569.228)" style="fill: rgb(var(--una-primary-600))" />
      <path id="Path_6647" data-name="Path 6647" d="M447.823,684.517a1.617,1.617,0,0,1-1.617-1.617v0a1.068,1.068,0,0,0-.3-.767l-.8-.8a.068.068,0,0,0-.092,0,6.733,6.733,0,0,0-1.139,1.275.537.537,0,0,0-.011.6,5.624,5.624,0,0,0,1.655,1.695,4.173,4.173,0,0,0,2.3.7,4.011,4.011,0,0,0,1.223-.194.067.067,0,0,0,.027-.112l-.8-.8a.068.068,0,0,0-.064-.018A1.62,1.62,0,0,1,447.823,684.517Z" transform="translate(-396.205 -570.872)" style="fill: rgb(var(--una-primary-600))" />
      <circle id="Ellipse_1379" data-name="Ellipse 1379" cx="7.716" cy="7.716" r="7.716" transform="translate(14.043 104.681)" fill="#d8dbe2" />
      <circle id="Ellipse_1380" data-name="Ellipse 1380" cx="5.915" cy="5.915" r="5.915" transform="translate(15.843 106.482)" fill="#fff" />
      <path id="Path_6648" data-name="Path 6648" d="M313.977,680.536a3.174,3.174,0,0,1-1.482-.505,9.637,9.637,0,0,1-2.327-1.741,8.833,8.833,0,0,1-1.748-2.327c-.62-1.128-.515-1.72-.4-1.972a1.574,1.574,0,0,1,.617-.662,2.97,2.97,0,0,1,.482-.256l.046-.02a.451.451,0,0,1,.37-.034,1.083,1.083,0,0,1,.351.269,5.84,5.84,0,0,1,.878,1.3,1.3,1.3,0,0,1,.172.534.844.844,0,0,1-.215.5l-.065.087c-.128.168-.156.217-.138.3.038.175.607.326,1.067.786s.676,1.09.851,1.127c.091.019.14-.01.314-.143l.077-.058a.848.848,0,0,1,.51-.228h0a1.22,1.22,0,0,1,.537.188,6.436,6.436,0,0,1,1.3.872,1.082,1.082,0,0,1,.27.35.454.454,0,0,1-.034.37l-.02.046a2.973,2.973,0,0,1-.257.481,1.575,1.575,0,0,1-.663.616A1.135,1.135,0,0,1,313.977,680.536Z" transform="translate(-289.947 -564.367)" style="fill: rgb(var(--una-primary-600))" />
      <circle id="Ellipse_1381" data-name="Ellipse 1381" cx="7.716" cy="7.716" r="7.716" transform="translate(72.817 104.681)" fill="#d8dbe2" />
      <circle id="Ellipse_1382" data-name="Ellipse 1382" cx="5.915" cy="5.915" r="5.915" transform="translate(74.617 106.482)" fill="#fff" />
      <path id="Path_6649" data-name="Path 6649" d="M588.536,685.231a.269.269,0,0,0-.269.269v.539c0,1.042-.844,1.347-1.886,1.347s-1.886-.306-1.886-1.347V685.5a.269.269,0,0,0-.539,0v.539a2.427,2.427,0,0,0,2.155,2.409v1.094a.269.269,0,0,0,.269.269h0a.269.269,0,0,0,.269-.269v-1.094a2.427,2.427,0,0,0,2.155-2.409V685.5A.269.269,0,0,0,588.536,685.231Z" transform="translate(-505.848 -573.939)" style="fill: rgb(var(--una-primary-600))" />
      <path id="Path_6650" data-name="Path 6650" d="M590.25,682.8a2.02,2.02,0,0,1-.952-.218.634.634,0,0,1-.395-.514v-1c0-.4.6-.721,1.337-.723h.01c.736,0,1.339.312,1.347.707,0,.005,0,.01,0,.015v1C591.6,682.472,591,682.8,590.25,682.8Z" transform="translate(-509.718 -570.117)" style="fill: rgb(var(--una-primary-600))" />
      <path id="Path_6651" data-name="Path 6651" d="M333.85,344.408h-95.9a3.043,3.043,0,0,1-3.04-3.04v-68.8a3.043,3.043,0,0,1,3.04-3.04h95.9a3.043,3.043,0,0,1,3.04,3.04v68.8A3.043,3.043,0,0,1,333.85,344.408Z" transform="translate(-232.832 -248.781)" fill="#fff" />
      <circle id="ab6171fa-7d69-4734-b81c-8dff60f9761b" cx="10.15" cy="10.15" r="10.15" transform="translate(42.45 43.084)" fill="#ffb8b8" />
      <path id="bf427902-b9bf-4946-b5d7-5c1c7e04535e" d="M409.982,351.027c.077-1.166.848-2.358,3.3-2.982,0,0,4.2-5.375,9.915-2.556a5.173,5.173,0,0,1,4.822,2.4,1.927,1.927,0,0,1,2.894.927s2.387,4.341,1.509,8.768-1.329,4.98-1.329,4.98,1.12-9.158-5.333-8.523-11.648-1.634-13.11,3.334a19.943,19.943,0,0,0-1.061,6.268s-4.058-4.116-2.678-7.357A16.022,16.022,0,0,0,409.982,351.027Z" transform="translate(-368.704 -307.557)" fill="#2f2e41" />
      <path id="Path_6652" data-name="Path 6652" d="M340.121,511.926h56.144c-.375-4.283-.62-7.469-.62-8.546,0-4.752-3.889-9.938-3.889-9.938l-1.392-.769-11.624-3.626-3.008-3.908a3.345,3.345,0,0,0-2.679-1.308l-9.5.071a3.355,3.355,0,0,0-2.253.893l-5.884,5.459-8.63,4.121-.015-.015-.09.067-1.281.922-.824.6s-2.161,2.593-3.889,10.37A38.307,38.307,0,0,0,340.121,511.926Z" transform="translate(-315.123 -416.405)" style="fill: rgb(var(--una-primary-600))" />
      <path id="Path_6653" data-name="Path 6653" d="M388.254,212.456H225.484a.242.242,0,1,1,0-.483h162.77a.242.242,0,1,1,0,.483Z" transform="translate(-225.266 -203.762)" fill="#cacaca" />
      <ellipse id="Ellipse_1383" data-name="Ellipse 1383" cx="2.599" cy="2.657" rx="2.599" ry="2.657" transform="translate(2.936 -3)" fill="#535a78" />
      <ellipse id="Ellipse_1384" data-name="Ellipse 1384" cx="2.599" cy="2.657" rx="2.599" ry="2.657" transform="translate(11.913 -3)" fill="#535a78" />
      <ellipse id="Ellipse_1385" data-name="Ellipse 1385" cx="2.599" cy="2.657" rx="2.599" ry="2.657" transform="translate(20.89 -3)" fill="#535a78" />
      <g id="Group_5768" data-name="Group 5768" transform="translate(0 -3)">
        <path id="Path_6654" data-name="Path 6654" d="M924.314,178.265h-6.379a.483.483,0,1,1,0-.966h6.378a.483.483,0,1,1,0,.966Z" transform="translate(-766.705 -176.641)" fill="#535a78" />
        <path id="Path_6655" data-name="Path 6655" d="M924.314,186.581h-6.379a.483.483,0,1,1,0-.966h6.378a.483.483,0,1,1,0,.966Z" transform="translate(-766.705 -183.146)" fill="#535a78" />
        <path id="Path_6656" data-name="Path 6656" d="M924.314,194.9h-6.379a.483.483,0,1,1,0-.966h6.378a.483.483,0,1,1,0,.966Z" transform="translate(-766.705 -189.65)" fill="#535a78" />
      </g>
      <path id="Path_6657" data-name="Path 6657" d="M799.316,525.974h-32a3.988,3.988,0,0,1-3.983-3.983V500.073a3.988,3.988,0,0,1,3.983-3.983h32a3.987,3.987,0,0,1,3.983,3.983v21.918A3.987,3.987,0,0,1,799.316,525.974Zm-32-27.787a1.889,1.889,0,0,0-1.887,1.887v21.918a1.889,1.889,0,0,0,1.887,1.887h32a1.889,1.889,0,0,0,1.887-1.887V500.073a1.889,1.889,0,0,0-1.887-1.887Z" transform="translate(-646.154 -425.995)" fill="#d8dbe2" />
      <path id="Path_6658" data-name="Path 6658" d="M802.7,528.417h-32a3.043,3.043,0,0,1-3.04-3.04V503.459a3.043,3.043,0,0,1,3.04-3.04h32a3.043,3.043,0,0,1,3.04,3.04v21.918A3.043,3.043,0,0,1,802.7,528.417Z" transform="translate(-649.542 -429.382)" fill="#fff" />
      <path id="Path_6659" data-name="Path 6659" d="M837.048,520.837c.185-1.154-.338-2-1.726-2.484-1.381-1.2-3.421-1.485-4.646.433a1.922,1.922,0,0,0-2.026,1.721c-1.125,1.307.124,6.107.494,7.935l1.975-.02,3.942-.039,1.987-.019.757-4.288A2.7,2.7,0,0,0,837.048,520.837Z" transform="translate(-696.886 -442.664)" style="fill: rgb(var(--una-gray-600))" />
      <path id="Path_6660" data-name="Path 6660" d="M816.909,585.524a9.516,9.516,0,0,1,1.631-.833l-5.615-6.667-3.711-.95-.09-.053-6.26.24-.027-.027-3.947,1.931-5.615,6.491a9.446,9.446,0,0,1,1.59.8l-.579,2.2H798.3l1.069-1.553h.054c0-.027,0-.054,0-.08l.75-1.091c.017.881-.007,1.79-.068,2.725h12.5c-.02-.777-.024-1.549-.024-2.316l1.4,2.028c.1-.153.217-.3.329-.443l.275.73h3.2l-.767-2.917A.684.684,0,0,0,816.909,585.524Z" transform="translate(-669.576 -489.299)" style="fill: rgb(var(--una-gray-600))" />
      <path id="Path_6661" data-name="Path 6661" d="M799.316,294.069h-32a3.988,3.988,0,0,1-3.983-3.983V268.168a3.987,3.987,0,0,1,3.983-3.983h32a3.987,3.987,0,0,1,3.983,3.983v21.918A3.987,3.987,0,0,1,799.316,294.069Zm-32-27.787a1.889,1.889,0,0,0-1.887,1.887v21.918a1.889,1.889,0,0,0,1.887,1.887h32a1.889,1.889,0,0,0,1.887-1.887V268.168a1.889,1.889,0,0,0-1.887-1.887Z" transform="translate(-646.154 -244.601)" fill="#d8dbe2" />
      <path id="Path_6662" data-name="Path 6662" d="M802.7,296.513h-32a3.043,3.043,0,0,1-3.04-3.04V271.555a3.043,3.043,0,0,1,3.04-3.04h32a3.043,3.043,0,0,1,3.04,3.04v21.918A3.043,3.043,0,0,1,802.7,296.513Z" transform="translate(-649.542 -247.989)" fill="#fff" />
      <path id="Path_6663" data-name="Path 6663" d="M834.721,292.618a4.211,4.211,0,0,0,8.236.757c.136-.142,1.648-1.757,1.1-3.042a6.647,6.647,0,0,1-.445-2.182c-.032-.484-.352-.979-1.368-1.238,0,0-1.744-2.231-4.115-1.061a2.147,2.147,0,0,0-2,.995.8.8,0,0,0-1.2.385,6.079,6.079,0,0,0-.626,3.639A15.821,15.821,0,0,0,834.721,292.618Z" transform="translate(-701.602 -261.284)" style="fill: rgb(var(--una-gray-600))" />
      <path id="Path_6664" data-name="Path 6664" d="M824.449,354.6a11.049,11.049,0,0,0-1.614-4.3l-.342-.247-.532-.383-.037-.028-.006.006-3.582-1.71-2.442-2.266a1.392,1.392,0,0,0-.935-.371l-3.944-.03a1.388,1.388,0,0,0-1.112.543l-1.249,1.622-4.824,1.505-.578.319a8.382,8.382,0,0,0-1.614,4.125c0,.447-.1,1.77-.258,3.547h23.3A15.893,15.893,0,0,0,824.449,354.6Z" transform="translate(-675.916 -308.025)" style="fill: rgb(var(--una-gray-600))" />
      <path id="Path_6665" data-name="Path 6665" d="M667.885,396.973H626.047a5.213,5.213,0,0,1-5.207-5.207V363.11a5.213,5.213,0,0,1,5.207-5.207h41.838a5.213,5.213,0,0,1,5.207,5.207v28.655a5.213,5.213,0,0,1-5.207,5.207Zm-41.838-36.329a2.469,2.469,0,0,0-2.467,2.467v28.655a2.469,2.469,0,0,0,2.467,2.467h41.838a2.47,2.47,0,0,0,2.466-2.467V363.11a2.469,2.469,0,0,0-2.467-2.467Z" transform="translate(-534.699 -317.907)" fill="#d8dbe2" />
      <path id="Path_6666" data-name="Path 6666" d="M672.31,363.564H630.473a3.98,3.98,0,0,0-3.975,3.975v28.655a3.979,3.979,0,0,0,3.975,3.973H672.31a3.979,3.979,0,0,0,3.975-3.973V367.539A3.98,3.98,0,0,0,672.31,363.564Z" transform="translate(-539.124 -322.334)" fill="#fff" />
      <g id="Group_5432" data-name="Group 5432" transform="translate(101.106 48.525)">
        <path id="Path_1949" data-name="Path 1949" d="M749.566,334.557s-6.744-3.5-9.2-8.377.148-8.6,3.075-9.667,8.84-1.567,12.713.236,4.346,6.12,2.631,9.076-5.735,4.907-7.243,5.44a17.05,17.05,0,0,1-1.978.621Z" transform="translate(-738.258 -314.594)" fill="#fff" />
        <g id="Group_3827" data-name="Group 3827">
          <path id="Path_1948" data-name="Path 1948" d="M2923.4-19983.8v6.08l2.71-1.322Z" transform="translate(-2912.044 19995.322)" fill="#d8dbe2" />
          <path id="Path_1947" data-name="Path 1947" d="M2852.188-20057.166a22.294,22.294,0,0,1-6.844-4.213c-3-2.828-4.091-4.846-4.447-7.635a6.705,6.705,0,0,1,2.107-6.1c2.351-2.213,5.709-2.59,9.145-2.508s6.132.059,8.728,2.508a7.547,7.547,0,0,1,2.268,5.65,9.361,9.361,0,0,1-3.964,6.789,27.594,27.594,0,0,1-6.993,4.012v-1.555s9.666-3.574,9.666-9.639a6.18,6.18,0,0,0-2.215-4.684c-2.05-1.648-4.264-1.648-7.489-1.744-3.053.039-5.783.232-7.719,1.826a5.825,5.825,0,0,0-2.11,4.674c.021,2.328,1.432,4.779,4.008,7.24a23.2,23.2,0,0,0,5.86,3.91Z" transform="translate(-2840.826 20077.635)" fill="url(#linear-gradient)" />
          <rect id="Rectangle_2722" data-name="Rectangle 2722" width="14.811" height="7.511" rx="3.755" transform="translate(3.821 4.006)" fill="#d8dbe2" />
          <rect id="Rectangle_2721" data-name="Rectangle 2721" width="12.414" height="5.508" rx="2.754" transform="translate(5.101 5.023)" fill="#2f385b" />
          <circle id="Ellipse_826" data-name="Ellipse 826" cx="1.409" cy="1.409" r="1.409" transform="translate(7.423 6.346)" style="fill: rgb(var(--una-primary-600))" />
          <circle id="Ellipse_827" data-name="Ellipse 827" cx="1.409" cy="1.409" r="1.409" transform="translate(12.47 6.346)" style="fill: rgb(var(--una-primary-600))" />
          <rect id="Rectangle_2724" data-name="Rectangle 2724" width="2.42" height="0.346" transform="translate(1.481 7.56)" fill="#d8dbe2" />
          <rect id="Rectangle_2725" data-name="Rectangle 2725" width="2.593" height="0.346" transform="translate(18.423 7.56)" fill="#d8dbe2" />
        </g>
      </g>
    </g>
  </svg>
</template>
