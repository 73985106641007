<script setup lang="ts">
const props = withDefaults(defineProps<{ divs?: { opacity: number, bottom: number }[] }>(), {
  /**
   * The divs to be rendered,
   * Note: The opacity and bottom values are safelisted to 10, 20, 30, etc. (refer to packages/preset/safelist.ts),
   * If you want to add more values, you need to add them to the safelist
   */
  divs: () => [
    { opacity: 0.6, bottom: 0 }, // opacity-60 bottom-0
    { opacity: 0.4, bottom: 5 }, // opacity-40 bottom-5
    { opacity: 0.2, bottom: 10 }, // opacity-20 bottom-10
  ],
})

function getClasses(index: number) {
  return `opacity-${Math.round(props.divs[index].opacity * 100)} ${getBottomClass(props.divs[index].bottom)}`
}

function getBottomClass(bottom: number) {
  return bottom >= 0 ? `bottom-${bottom}` : `-bottom-${-bottom}`
}
</script>

<template>
  <div
    v-for="(item, index) in divs"
    :key="index"
    class="sticky inset-x-0 h-5 w-full bg-muted"
    :class="[getClasses(index)]"
  />
</template>
